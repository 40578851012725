.mapouter{
    position:relative;
    text-align:right;
    height:500px;
    width:100%;
}

.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:500px;
    width:100%;
}


/***

==================================================================
		Contact Map Section
==================================================================

***/

.contact-map-section{
    position: relative;
}

.contact-map-section .outer-container{
    margin-bottom: -140px;
}

.contact-map-section .map-outer{
    position: relative;
    height: 500px;
}

.contact-map-section .map-canvas{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
}

.map-data{
    text-align:center;
    font-size:14px;
    font-weight:400;
    line-height:1.8em;
    padding: 10px;
}

.map-data h6{
    font-size:16px;
    font-weight:700;
    text-align:center;
    margin-bottom:10px;
    color:#121212;
}
