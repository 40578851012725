
/***

====================================================================
		Team Section
====================================================================

***/

.team-section{
    position: relative;
    padding: 100px 0 70px;
}

.team-section .sec-title{
    margin-bottom: 60px;
}

.team-block{
    position: relative;
    margin-bottom: 60px;
}

.team-block .inner-box{
    position: relative;
}

.team-block .image-box{
    position: relative;
}

.team-block .image-box .image{
    position: relative;
    margin-bottom: 0;
    background-color: #89c540;
    overflow: hidden;
}

.team-block .image-box .image img{
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.team-block .inner-box:hover .image img{
    opacity: .70;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

.team-block .lower-content{
    position:relative;
    text-align:center;
    z-index:1;
    padding:34px 15px 50px;
    background-color:#f3f5f9;
    border-bottom:3px solid #001e57;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.team-block .inner-box:hover .lower-content{
    border-color:#89c540;
    -webkit-box-shadow:0px 0px 30px rgba(0,0,0,0.10);
    -ms-box-shadow:0px 0px 30px rgba(0,0,0,0.10);
    box-shadow:0px 0px 30px rgba(0,0,0,0.10);
    background-color: #001e57;
}

.team-block .lower-content h3{
    position:relative;
    font-size:20px;
    font-weight:700;
    line-height:1.2em;
    color: #222222;
    margin-bottom: 5px;
}

.team-block .lower-content h3 a{
    position:relative;
    color:#222222;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.team-block .inner-box:hover .lower-content h3 a{
    color:#ffffff;
}

.team-block .lower-content .designation{
    position:relative;
    font-size:14px;
    line-height: 24px;
    font-weight: 700;
    color: #555555;
    font-style: italic;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.team-block .inner-box:hover .lower-content .designation{
    color: #ffffff;
}


.team-block .lower-content .arrow{
    position: absolute;
    left: 50%;
    bottom: 0px;
    margin-bottom: -22px;
    width: 44px;
    height: 44px;
    color: #89c540;
    font-size: 10px;
    line-height: 44px;
    text-align: center;
    border-radius: 50%;
    font-weight: 800;
    margin-left: -20px;
    background-color: #ffffff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-box-shadow:5px 20px 30px rgba(0,0,0,0.06);
    -ms-box-shadow:5px 20px 30px rgba(0,0,0,0.06);
    box-shadow:5px 20px 30px rgba(0,0,0,0.06);
}

.team-block .inner-box:hover .lower-content .arrow{
    color:#ffffff;
    background-color:#89c540;
}
