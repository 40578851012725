@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon.eot");
  src: url("../fonts/flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon.woff") format("woff"),
       url("../fonts/flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
 	font-family: Flaticon;
	font-style: normal;
}

.flaticon-trifolded-map:before { content: "\f100"; }
.flaticon-smiling-girl:before { content: "\f101"; }
.flaticon-clock:before { content: "\f102"; }
.flaticon-girl:before { content: "\f103"; }
.flaticon-notebook:before { content: "\f104"; }
.flaticon-edit:before { content: "\f105"; }
.flaticon-placeholder:before { content: "\f106"; }
.flaticon-paper-plane:before { content: "\f107"; }
.flaticon-user:before { content: "\f108"; }
.flaticon-unlink:before { content: "\f109"; }
.flaticon-success:before { content: "\f10a"; }
.flaticon-search:before { content: "\f10b"; }
.flaticon-drop:before { content: "\f10c"; }
.flaticon-christmas-tree:before { content: "\f10d"; }
.flaticon-plus:before { content: "\f10e"; }
.flaticon-rocket-ship:before { content: "\f10f"; }
.flaticon-battery:before { content: "\f110"; }
.flaticon-target:before { content: "\f111"; }
.flaticon-classroom:before { content: "\f112"; }
.flaticon-first-aid-kit:before { content: "\f113"; }
.flaticon-tablet:before { content: "\f114"; }
.flaticon-dustbin:before { content: "\f115"; }
.flaticon-telegram-logo:before { content: "\f116"; }
.flaticon-business:before { content: "\f117"; }
.flaticon-4-square-shapes:before { content: "\f118"; }
.flaticon-speech-bubble-and-three-dots:before { content: "\f119"; }
.flaticon-man:before { content: "\f11a"; }
.flaticon-music:before { content: "\f11b"; }
.flaticon-tree-of-love:before { content: "\f11c"; }
.flaticon-play-button:before { content: "\f11d"; }
.flaticon-long-arrow-pointing-to-the-right:before { content: "\f11e"; }
.flaticon-envelope-of-white-paper:before { content: "\f11f"; }
.flaticon-royal-crown-of-elegant-vintage-design:before { content: "\f120"; }
.flaticon-desktop-computer-with-magnifying-lens-focusing-on-data:before { content: "\f121"; }
.flaticon-test-tube-flask-and-drop-of-blood:before { content: "\f122"; }
.flaticon-arrows:before { content: "\f123"; }
.flaticon-stocks-graphic-on-laptop-monitor:before { content: "\f124"; }
.flaticon-attachment:before { content: "\f125"; }
.flaticon-transport:before { content: "\f126"; }
.flaticon-recycle-triangle-of-three-arrows-outlines:before { content: "\f127"; }
.flaticon-arrows-1:before { content: "\f128"; }
.flaticon-snowflake:before { content: "\f129"; }
.flaticon-clock-1:before { content: "\f12a"; }
.flaticon-find-in-folder:before { content: "\f12b"; }
.flaticon-smartphone:before { content: "\f12c"; }
.flaticon-arrow-pointing-to-right:before { content: "\f12d"; }
.flaticon-gas-pump:before { content: "\f12e"; }
.flaticon-house-silhouette:before { content: "\f12f"; }
.flaticon-cancel-music:before { content: "\f130"; }
.flaticon-arrows-2:before { content: "\f131"; }
.flaticon-floor:before { content: "\f132"; }
.flaticon-exercise:before { content: "\f133"; }
.flaticon-love-planet:before { content: "\f134"; }
.flaticon-workers:before { content: "\f135"; }
.flaticon-open-magazine:before { content: "\f136"; }
.flaticon-confirm-schedule:before { content: "\f137"; }
.flaticon-star:before { content: "\f138"; }
.flaticon-right-arrow:before { content: "\f139"; }
.flaticon-left-arrow:before { content: "\f13a"; }
.flaticon-order:before { content: "\f13b"; }
.flaticon-key:before { content: "\f13c"; }
.flaticon-medical:before { content: "\f13d"; }
.flaticon-bank:before { content: "\f13e"; }
.flaticon-smartphone-1:before { content: "\f13f"; }
.flaticon-plug:before { content: "\f140"; }
.flaticon-arrows-3:before { content: "\f141"; }
.flaticon-arrows-4:before { content: "\f142"; }
.flaticon-money-bag:before { content: "\f143"; }
.flaticon-next:before { content: "\f144"; }
.flaticon-back:before { content: "\f145"; }
.flaticon-reload:before { content: "\f146"; }
.flaticon-headphones:before { content: "\f147"; }
.flaticon-shopping-bag:before { content: "\f148"; }
.flaticon-signs:before { content: "\f149"; }
.flaticon-diamond:before { content: "\f14a"; }
.flaticon-chat:before { content: "\f14b"; }
.flaticon-phone:before { content: "\f14c"; }
.flaticon-down-chevron:before { content: "\f14d"; }
.flaticon-up-chevron:before { content: "\f14e"; }
.flaticon-stairs:before { content: "\f14f"; }
.flaticon-music-player:before { content: "\f150"; }
.flaticon-play-button-1:before { content: "\f151"; }
.flaticon-speaker:before { content: "\f152"; }
.flaticon-menu:before { content: "\f153"; }
.flaticon-calculator:before { content: "\f154"; }
.flaticon-share:before { content: "\f155"; }
.flaticon-map:before { content: "\f156"; }
.flaticon-hourglass:before { content: "\f157"; }
.flaticon-layers:before { content: "\f158"; }
.flaticon-settings:before { content: "\f159"; }
.flaticon-clock-2:before { content: "\f15a"; }
.flaticon-alarm-clock:before { content: "\f15b"; }
.flaticon-file:before { content: "\f15c"; }
.flaticon-list:before { content: "\f15d"; }
.flaticon-home:before { content: "\f15e"; }
.flaticon-star-1:before { content: "\f15f"; }
.flaticon-bar-chart:before { content: "\f160"; }
.flaticon-handshake:before { content: "\f161"; }
.flaticon-time:before { content: "\f162"; }
.flaticon-worldwide:before { content: "\f163"; }
.flaticon-board:before { content: "\f164"; }
.flaticon-twitter:before { content: "\f165"; }
.flaticon-smartphone-2:before { content: "\f166"; }
.flaticon-mobile:before { content: "\f167"; }
.flaticon-location-pin:before { content: "\f168"; }
.flaticon-maps-and-flags:before { content: "\f169"; }
.flaticon-add:before { content: "\f16a"; }
.flaticon-substract:before { content: "\f16b"; }
.flaticon-phone-call:before { content: "\f16c"; }
.flaticon-mail:before { content: "\f16d"; }
.flaticon-folder:before { content: "\f16e"; }
.flaticon-shopping-cart:before { content: "\f16f"; }
.flaticon-monitor:before { content: "\f170"; }
.flaticon-menu-1:before { content: "\f171"; }
.flaticon-cancel:before { content: "\f172"; }
.flaticon-up-arrow-inside-circle:before { content: "\f173"; }
.flaticon-circular-down-arrow-button:before { content: "\f174"; }
.flaticon-24-hours:before { content: "\f175"; }
.flaticon-t-shirt-outline:before { content: "\f176"; }
.flaticon-folder-outline:before { content: "\f177"; }
.flaticon-quote-1:before { content: "\f178"; }
.flaticon-play-button-2:before { content: "\f179"; }
.flaticon-right-quotation-sign:before { content: "\f17a"; }
.flaticon-up-arrow:before { content: "\f17b"; }
.flaticon-open-mail-interface-symbol:before { content: "\f17c"; }
.flaticon-menu-button:before { content: "\f17d"; }
.flaticon-laptop:before { content: "\f17e"; }
.flaticon-smartphone-3:before { content: "\f17f"; }
.flaticon-add-1:before { content: "\f180"; }
.flaticon-user-1:before { content: "\f181"; }
.flaticon-briefcase:before { content: "\f182"; }
.flaticon-play-button-3:before { content: "\f183"; }
.flaticon-play-button-4:before { content: "\f184"; }
.flaticon-support:before { content: "\f185"; }
.flaticon-telephone-receiver:before { content: "\f186"; }
.flaticon-close:before { content: "\f187"; }
.flaticon-heart:before { content: "\f188"; }
.flaticon-renewable:before { content: "\f189"; }
.flaticon-shopping-cart-1:before { content: "\f18a"; }
.flaticon-bar-chart-1:before { content: "\f18b"; }
.flaticon-profit:before { content: "\f18c"; }
.flaticon-settings-1:before { content: "\f18d"; }
.flaticon-tick-inside-a-circle:before { content: "\f18e"; }
.flaticon-confirm:before { content: "\f18f"; }
.flaticon-edit-1:before { content: "\f190"; }
.flaticon-share-1:before { content: "\f191"; }
.flaticon-setting:before { content: "\f192"; }
.flaticon-stars:before { content: "\f193"; }
.flaticon-check:before { content: "\f194"; }
.flaticon-moon:before { content: "\f195"; }
.flaticon-star-2:before { content: "\f196"; }
.flaticon-decreasing:before { content: "\f197"; }
.flaticon-graph:before { content: "\f198"; }
.flaticon-search-1:before { content: "\f199"; }
.flaticon-multi-tab:before { content: "\f19a"; }
.flaticon-graph-1:before { content: "\f19b"; }
.flaticon-graph-2:before { content: "\f19c"; }
.flaticon-tick:before { content: "\f19d"; }
.flaticon-next-1:before { content: "\f19e"; }
.flaticon-trophy:before { content: "\f19f"; }
.flaticon-pin:before { content: "\f1a0"; }
.flaticon-small-calendar:before { content: "\f1a1"; }
.flaticon-alarm-clock-1:before { content: "\f1a2"; }
.flaticon-shopping-cart-2:before { content: "\f1a3"; }
.flaticon-magnifying-glass:before { content: "\f1a4"; }
.flaticon-close-1:before { content: "\f1a5"; }
.flaticon-mail-1:before { content: "\f1a6"; }
.flaticon-factory-5:before { content: "\f1a7"; }
.flaticon-factory:before { content: "\f1a8"; }
.flaticon-factory-1:before { content: "\f1a9"; }
.flaticon-manufacture:before { content: "\f1aa"; }
.flaticon-manufacture-1:before { content: "\f1ab"; }
.flaticon-factory-2:before { content: "\f1ac"; }
.flaticon-manufacturing:before { content: "\f1ad"; }
.flaticon-factory-3:before { content: "\f1ae"; }
.flaticon-factory-4:before { content: "\f1af"; }
.flaticon-branch:before { content: "\f1b0"; }
.flaticon-company:before { content: "\f1b1"; }
.flaticon-power-plant:before { content: "\f1b2"; }
.flaticon-branch-1:before { content: "\f1b3"; }
.flaticon-manufacturer:before { content: "\f1b4"; }
.flaticon-robot:before { content: "\f1b5"; }
.flaticon-architecture-and-city:before { content: "\f1b6"; }
.flaticon-conveyor:before { content: "\f1b7"; }
.flaticon-branch-2:before { content: "\f1b8"; }
.flaticon-oil-tanker:before { content: "\f1b9"; }
.flaticon-truck:before { content: "\f1ba"; }
.flaticon-tanker-truck:before { content: "\f1bb"; }
.flaticon-garbage-truck:before { content: "\f1bc"; }
.flaticon-trailer:before { content: "\f1bd"; }
.flaticon-helmet:before { content: "\f1be"; }
.flaticon-helmet-1:before { content: "\f1bf"; }
.flaticon-helmet-2:before { content: "\f1c0"; }
.flaticon-conveyor-1:before { content: "\f1c1"; }
.flaticon-robot-1:before { content: "\f1c2"; }
.flaticon-conveyor-2:before { content: "\f1c3"; }
.flaticon-drill:before { content: "\f1c4"; }
.flaticon-drill-1:before { content: "\f1c5"; }
.flaticon-drill-2:before { content: "\f1c6"; }
.flaticon-drill-3:before { content: "\f1c7"; }
.flaticon-drill-4:before { content: "\f1c8"; }
.flaticon-construction-and-tools:before { content: "\f1c9"; }
.flaticon-safety:before { content: "\f1ca"; }
.flaticon-tools:before { content: "\f1cb"; }
.flaticon-settings-2:before { content: "\f1cc"; }
.flaticon-tools-1:before { content: "\f1cd"; }
.flaticon-customer-support:before { content: "\f1ce"; }
.flaticon-tools-2:before { content: "\f1cf"; }
.flaticon-tape-measure:before { content: "\f1d0"; }
.flaticon-measuring-tape:before { content: "\f1d1"; }
.flaticon-measuring-tape-1:before { content: "\f1d2"; }
.flaticon-measuring-tape-2:before { content: "\f1d3"; }
.flaticon-measuring-tape-3:before { content: "\f1d4"; }
.flaticon-hammer:before { content: "\f1d5"; }
.flaticon-hammer-1:before { content: "\f1d6"; }
.flaticon-telephone:before { content: "\f1d7"; }
.flaticon-work:before { content: "\f1d8"; }
.flaticon-clock-3:before { content: "\f1d9"; }
.flaticon-home-1:before { content: "\f1da"; }
.flaticon-fan:before { content: "\f1db"; }
.flaticon-engineer-1:before { content: "\f1dc"; }
.flaticon-worker:before { content: "\f1dd"; }
.flaticon-worker-1:before { content: "\f1de"; }
.flaticon-nuclear-plant:before { content: "\f1df"; }
.flaticon-pollution:before { content: "\f1e0"; }
.flaticon-website:before { content: "\f1e1"; }
.flaticon-home-2:before { content: "\f1e2"; }
.flaticon-market:before { content: "\f1e3"; }
.flaticon-city-development:before { content: "\f1e4"; }
.flaticon-star-3:before { content: "\f1e5"; }
.flaticon-like:before { content: "\f1e6"; }
.flaticon-folder-1:before { content: "\f1e7"; }
.flaticon-folder-2:before { content: "\f1e8"; }
.flaticon-quote:before { content: "\f1e9"; }
.flaticon-gear:before { content: "\f1ea"; }
.flaticon-settings-3:before { content: "\f1eb"; }
.flaticon-engineer-2:before { content: "\f1ec"; }
.flaticon-constructor-with-hard-hat-protection-on-his-head:before { content: "\f1ed"; }
.flaticon-world:before { content: "\f1ee"; }
.flaticon-earth-globe:before { content: "\f1ef"; }
.flaticon-flask-outline:before { content: "\f1f0"; }
.flaticon-chemistry-lab-instrument:before { content: "\f1f1"; }
.flaticon-flask:before { content: "\f1f2"; }
.flaticon-engineer:before { content: "\f1f3"; }
.flaticon-robotic-arm:before { content: "\f1f4"; }
.flaticon-open-envelope:before { content: "\f1f5"; }
.flaticon-link-symbol:before { content: "\f1f6"; }
.flaticon-web-link:before { content: "\f1f7"; }
.flaticon-phone-call-1:before { content: "\f1f8"; }
.flaticon-ecology-and-environment:before { content: "\f1f9"; }
.flaticon-repairing-service:before { content: "\f1fa"; }
.flaticon-telephone-1:before { content: "\f1fb"; }
.flaticon-settings-4:before { content: "\f1fc"; }
.flaticon-setup:before { content: "\f1fd"; }
.flaticon-gear-1:before { content: "\f1fe"; }