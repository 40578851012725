
/***

====================================================================
	What We Offer Style Two
====================================================================

***/

.services-section-three.style-two{
    position: relative;
    padding: 100px 0 20px;
}

.services-section-three.style-two .services-block-three{
    margin-bottom: 80px;
}

.services-section-three.style-two .services-block-three .inner-box .lower-content .read-more{
    color: #89c540;
}

.services-section-three.style-two .services-block-three .inner-box .lower-content .read-more:hover{
    color: #222222;
}

.services-section-three.style-two .sec-title{
    margin-bottom: 60px;
}

.services-section-three.style-two .sec-title .text{
    position: relative;
    font-size: 16px;
    line-height: 1.8em;
    margin-top: 80px;
}

.services-section-three.style-two .services-block-five{
    margin-bottom: 70px;
}

.services-block-five{
    position: relative;
}

.services-block-five .inner-box{
    position: relative;
    text-align: center;
}

.services-block-five .image-box{
    position: relative;
    margin-bottom: 28px;
}

.services-block-five .image-box img{
    display: inline-block;
    max-width: 100%;
    height: auto;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.services-block-five .inner-box:hover .image-box img{
    -webkit-transform: scale(1.1) translateY(-5px);
    -moz-transform: scale(1.1) translateY(-5px);
    -ms-transform: scale(1.1) translateY(-5px);
    -o-transform: scale(1.1) translateY(-5px);
    transform: scale(1.1) translateY(-5px);
}


.services-block-five .content-box{
    position: relative;
}

.services-block-five h4{
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 700;
    margin-bottom: 15px;
}

.services-block-five h4 a{
    position:relative;
    display: inline-block;
    color: #222222;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.services-block-five h4 a:hover{
    color: #89c540;
    text-decoration: underline;
}

.services-block-five .text{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #555555;
    font-weight: 400;
    margin-bottom: 25px;
}

.services-block-five .link-box a{
    position:relative;
    color:#89c540;
    font-weight:700;
    font-size: 14px;
    display:inline-block;
    text-transform:uppercase;
    text-decoration:underline;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block-five .link-box a i{
    position:relative;
    font-size:16px;
    font-weight:700;
    padding-left:5px;
    color:#001e57;
}

.services-block-five .link-box a:hover{
    color:#001e57;
}
