.sec-title h2{
    position: relative;
    display: block;
    font-size:44px;
    line-height: 1.2em;
    color: #001e57;
    font-weight: 800;
    padding-bottom:25px;
}

.sec-title h2:before{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    width:120px;
    height:1px;
    background-color:#89c540;
}

.sec-title h2:after{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    width:40px;
    height:1px;
    background-color:#001e57;
}


/***

====================================================================
	Services Section Three
====================================================================

***/


.services-section-three{
    position:relative;
    padding: 100px 0px 50px;
    background-color: #ffffff;
}


/* Services Block Three */

.services-block-three{
    position:relative;
    margin-bottom:50px;
}

.services-block-three .inner-box{
    position:relative;
    display:block;
}

.services-block-three .inner-box .image{
    position:relative;
    display: block;
    overflow: hidden;
}

.services-block-three .inner-box .image a{
    position: relative;
    display: block;
}

.services-block-three .inner-box .image a:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #89c540;
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    z-index: 1;
}

.services-block-three .inner-box:hover .image a:before{
    opacity: 0.70;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
}

.services-block-three .inner-box .image img{
    position:relative;
    width:100%;
    display:block;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block-three .inner-box:hover .image img{
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.services-block-three .inner-box .lower-content{
    position:relative;
    padding-top:25px;
}

.services-block-three .inner-box .lower-content h3{
    position:relative;
    font-size:22px;
    font-weight:700;
    line-height:1.4em;
    margin-bottom:10px;
}

.services-block-three .inner-box .lower-content h3 a{
    position:relative;
    color:#222222;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block-three .inner-box .lower-content h3 a:hover{
    color:#89c540;
    text-decoration:underline;
}

.services-block-three .inner-box .text{
    margin-bottom:40px;
}

.services-block-three .inner-box .lower-content .text{
    position:relative;
    font-size:16px;
    line-height:1.6em;
    color:#555555;
    margin-bottom:15px;
}

.services-block-three .inner-box .text p{
    margin-bottom:25px;
}

.services-block-three .inner-box .lower-content .read-more{
    position:relative;
    color:#555555;
    font-weight:700;
    font-size: 14px;
    display:inline-block;
    text-transform:uppercase;
    text-decoration:underline;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block-three .inner-box .lower-content .read-more .fas{
    position:relative;
    font-size:16px;
    font-weight:700;
    padding-left:5px;
    color:#001e57;
}

.services-block-three .inner-box .lower-content .read-more:hover{
    color:#89c540;
}

/* Services Block Four */

.services-block-four{
    position:relative;
    margin-bottom:50px;
}

.services-block-four .inner-box{
    position:relative;
    display:block;
    color: #ffffff;
}

.services-block-four .inner-box .image{
    position:relative;
}

.services-block-four .inner-box .image img{
    position:relative;
    width:100%;
    display:block;
}

.services-block-four .inner-box .over-content{
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.70);
}

.services-block-four .inner-box .content-box{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 30px 30px;
}

.services-block-four .inner-box .content-box h3{
    position:relative;
    font-size:28px;
    font-weight:700;
    line-height:1.4em;
    margin-bottom:10px;
}

.services-block-four .inner-box .content-box .logo{
    position:relative;
    margin-bottom:10px;
}

.services-block-four .inner-box .content-box h3 a{
    position:relative;
    color:#ffffff;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block-four .inner-box .content-box h3 a:hover{
    color:#89c540;
    text-decoration:underline;
}

.services-block-four .inner-box .content-box .text{
    position:relative;
    font-size:16px;
    line-height:1.6em;
    color:#ffffff;
    margin-bottom:0px;
}


/***

====================================================================
	What We Offer Style Two
====================================================================

***/

.services-section-three.style-two{
    position: relative;
    padding: 100px 0 20px;
}

.services-section-three.style-two .services-block-three{
    margin-bottom: 80px;
}

.services-section-three.style-two .services-block-three .inner-box .lower-content .read-more{
    color: #89c540;
}

.services-section-three.style-two .services-block-three .inner-box .lower-content .read-more:hover{
    color: #222222;
}

.services-section-three.style-two .sec-title{
    margin-bottom: 60px;
}

.services-section-three.style-two .sec-title .text{
    position: relative;
    font-size: 16px;
    line-height: 1.8em;
    margin-top: 80px;
}

.services-section-three.style-two .services-block-five{
    margin-bottom: 70px;
}

.services-block-five{
    position: relative;
}

.services-block-five .inner-box{
    position: relative;
    text-align: center;
}

.services-block-five .image-box{
    position: relative;
    margin-bottom: 28px;
}

.services-block-five .image-box img{
    display: inline-block;
    max-width: 100%;
    height: auto;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.services-block-five .inner-box:hover .image-box img{
    -webkit-transform: scale(1.1) translateY(-5px);
    -moz-transform: scale(1.1) translateY(-5px);
    -ms-transform: scale(1.1) translateY(-5px);
    -o-transform: scale(1.1) translateY(-5px);
    transform: scale(1.1) translateY(-5px);
}


.services-block-five .content-box{
    position: relative;
}

.services-block-five h4{
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 700;
    margin-bottom: 15px;
}

.services-block-five h4 a{
    position:relative;
    display: inline-block;
    color: #222222;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.services-block-five h4 a:hover{
    color: #89c540;
    text-decoration: underline;
}

.services-block-five .text{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #555555;
    font-weight: 400;
    margin-bottom: 25px;
}

.services-block-five .link-box a{
    position:relative;
    color:#89c540;
    font-weight:700;
    font-size: 14px;
    display:inline-block;
    text-transform:uppercase;
    text-decoration:underline;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block-five .link-box a i{
    position:relative;
    font-size:16px;
    font-weight:700;
    padding-left:5px;
    color:#001e57;
}

.services-block-five .link-box a:hover{
    color:#001e57;
}
