
/***

====================================================================
	Contact Page Section
====================================================================

***/

.contact-page-section{
    position:relative;
    padding:100px 0px 0px;
}

.contact-page-section .info-column{
    position:relative;
    margin-bottom:40px;
}

.contact-page-section .info-column .inner-column{
    position:relative;
    margin-right:-20px;
    padding:50px 35px 45px;
    background-color: #001e57;
    -webkit-box-shadow:0px 0px 45px rgba(0,0,0,0.20);
    -ms-box-shadow:0px 0px 45px rgba(0,0,0,0.20);
    box-shadow:0px 0px 45px rgba(0,0,0,0.20);
}

.contact-page-section .info-column .inner-column .title-box{
    position:relative;
}

.contact-page-section .info-column .inner-column .title-box h3{
    position:relative;
    color:#ffffff;
    font-size:32px;
    font-weight:700;
    line-height:1.3em;
}

.contact-page-section .info-column .inner-column .title-box .title-text{
    position:relative;
    font-size:16px;
    font-weight:400;
    line-height:1.5em;
    margin-top:8px;
    margin-bottom:30px;
    color:rgba(255,255,255,0.80);
}

.contact-page-section .info-column .inner-column .social-links{
    position:relative;
}

.contact-page-section .info-column .inner-column .social-links li{
    position:relative;
    margin-right:4px;
    margin-bottom:5px;
    display:inline-block;
}

.contact-page-section .info-column .inner-column .social-links li a{
    position:relative;
    color:#ffffff;
    width:45px;
    height:45px;
    border-radius:2px;
    display:inline-block;
    text-align:center;
    line-height:43px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border:1px solid rgba(255,255,255,0.30);
}

.contact-page-section .info-column .inner-column .social-links li a:hover{
    color:#89c540;
    border-color:#ffffff;
    background-color:#ffffff;
}

/* Contact Info List */

.contact-info-list{
    position:relative;
}

.contact-info-list li{
    position:relative;
    margin-bottom:30px;
    font-size:16px;
    min-height:50px;
    line-height:1.8em;
    padding-left:50px;
    color:rgba(255,255,255,0.80);
}

.contact-info-list li strong{
    font-weight:700;
    font-size:18px;
    display:block;
}

.contact-info-list li .icon{
    position:absolute;
    left:0px;
    top:8px;
    font-size:26px;
    line-height:1em;
}

.contact-page-section .form-column{
    position:relative;
}

.contact-page-section .form-column .inner-column{
    position:relative;
    padding-left:60px;
}

.contact-page-section .form-column .inner-column .row{
    margin:0px -11px;
}

.contact-page-section .form-column .inner-column .contact-form .form-group{
    padding:0px 10px;
}
