
/***

====================================================================
	Main Header style
====================================================================

***/

.main-header{
    position: relative;
    display: block;
    width: 100%;
    z-index: 99999;
}

.main-header .header-top{
    position: relative;
    background-color:#001e57;
}

.main-header .header-top .top-right{
    position: relative;
    float: right;
}

.main-header .header-top .info-list{
    position: relative;
}

.main-header .header-top .info-list li{
    position: relative;
    float: left;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 700;
    padding: 15px 0px;
    margin-left: 24px;
}

.main-header .header-top .info-list li a{
    color: #ffffff;
}

.main-header .header-top .info-list li .icon{
    position:relative;
    margin-left:8px;
    color:#89c540;
}

.main-header .header-top .info-list li a:hover .txt{
    color:#89c540;
    text-decoration:underline;
}

/* Top Left*/

.main-header .header-top .top-left{
    position: relative;
    float: left;
}

.main-header .header-top .top-left .text:before{
    position:absolute;
    content:'';
    right:0%;
    top:0px;
    height:100%;
    width:100000%;
    display:block;
    z-index:-1;
    background-color:#031331;
}

.main-header .header-top .top-left .text{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    float:left;
    z-index:1;
    font-weight: 400;
    padding: 15px 0px;
    padding-right:40px;
}

.main-header .header-top .top-left .social-links{
    position:relative;
    float:left;
    padding:18px 0px;
    margin-left:40px;
}

.main-header .header-top .top-left .social-links li{
    position:relative;
    margin-right:18px;
    display:inline-block;
}

.main-header .header-top .top-left .social-links li a{
    position:relative;
    color:#ffffff;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-header .header-top .top-left .social-links li a:hover{
    color:#89c540;
}

.main-header .header-top .top-right{
    position:relative;
    float:right;
}

.main-header .header-top-two{
    position: relative;
    background-color:#f3f5f9;
    z-index: 7;
}

.main-header .header-top-two .top-right{
    position: relative;
    float: right;
}

.header-top-two .social-links{
    position:relative;
    float:left;
    padding:14px 0px;
}

.header-top-two .social-links li{
    position:relative;
    margin-left:18px;
    display:inline-block;
}

.header-top-two .social-links li a{
    position:relative;
    display: block;
    color:#555555;
    line-height: 30px;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.header-top-two .social-links li a:hover{
    color:#89c540;
}

.header-top-two .change-location{
    position: relative;
    float: left;
    margin-left: 30px;
}

.header-top-two .change-location .location{
    position: relative;
    padding: 14px 0px;
}

.header-top-two .change-location .location .dropdown-menu{
    left: auto !important;
    right: 0px;
    top:100% !important;
    width:170px;
    border-radius:0px;
    padding:0px;
    margin-top:0px;
    background-color:#222222;
    border:none;
    border-top:2px solid #89c540;
    transform:none !important;
}

.header-top-two .change-location .location > a{
    display: block;
    border:0px;
    padding:0px;
    font-size:14px;
    background:#001e57;
    font-weight:400;
    border-radius:0px;
    padding-left:15px;
    padding-right:15px;
    line-height: 30px;
    color:#ffffff !important;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.header-top-two .change-location .location > a .fa{
    padding-left: 5px;
    vertical-align: middle;
}

.header-top-two .change-location .location .dropdown-menu > li{
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(255,255,255,0.20);
}

.header-top-two .change-location .location .dropdown-menu > li:last-child{
    border-bottom:none;
}

.header-top-two .change-location .location .dropdown-menu > li > a{
    padding:8px 15px !important;
    line-height: 24px;
    display: block;
    color:#ffffff !important;
    text-align:left;
    font-size:15px;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.header-top-two .change-location .location .dropdown-menu > li > a:hover{
    color:#ffffff;
    background:#89c540;
}

.header-top-two .top-left{
    position: relative;
    float: left;
    padding: 14px 0px;
}

.header-top-two .top-left .text{
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 30px;
    color: #222222;
}

/* Header Top Three*/

.main-header .header-top-three{
    position: relative;
    background:none;
    border-bottom: 1px solid rgba(255,255,255,0.20);
}

.main-header .header-top-three .top-left{
    position: relative;
    float: left;
}

.main-header .header-top-three .info-list{
    position: relative;
}

.main-header .header-top-three .info-list li{
    position: relative;
    float: left;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 500;
    padding: 15px 0px;
    margin-right: 25px;
}

.main-header .header-top-three .info-list li a{
    color: #ffffff;
}

.main-header .header-top-three .info-list li .icon{
    position:relative;
    margin-left:8px;
    color:#89c540;
}

.main-header .header-top-three .info-list li a:hover .txt{
    color:#89c540;
    text-decoration:underline;
}

.main-header .header-top-three .top-right{
    position: relative;
    float: right;
}

.main-header .header-top-three .social-links{
    position:relative;
    float:right;
    padding:18px 0px;
    margin-left:40px;
}

.main-header .header-top-three .social-links li{
    position:relative;
    margin-right:18px;
    display:inline-block;
}

.main-header .header-top-three .social-links li a{
    position:relative;
    color:#ffffff;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-header .header-top-three .social-links li a:hover{
    color:#89c540;
}

/*=== Header Upper ===*/

.main-header .header-upper{
    position:relative;
    top: 0;
    z-index:5;
    background-color: #ffffff;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-header .header-upper .logo-outer{
    position:relative;
    float: left;
    z-index:25;
    /* padding: 32px 0px; */
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-header .logo-outer .logo img{
    position:relative;
    display:inline-block;
    max-width:100%;
}

.main-header .header-upper .nav-outer{
    float: right;
}

/*=== Header Lower ===*/

.main-header .header-lower{
    position: relative;
}

.main-menu{
    position:relative;
    float:left;
}

.main-menu .navbar-collapse{
    padding:0px;
}

.main-menu .navigation{
    position:relative;
    margin:0px;
}

.main-menu .navigation > li{
    position:relative;
    float:left;
    padding:28px 0px;
    margin-right: 40px;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-menu .navigation > li > a{
    position:relative;
    display:block;
    text-align:center;
    font-size:16px;
    line-height:35px;
    font-weight:700;
    opacity:1;
    color:#222222;
    padding: 0px;
    text-transform:none;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a{
    color: #89c540;
}

.main-menu .navigation > li > ul{
    position:absolute;
    left:0px;
    top:100%;
    width:240px;
    z-index:100;
    display:none;
    padding:0px 0px;
    background-color:#262626;
    opacity:0;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    -webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul.from-right{
    left:auto;
    right:0px;
}

.main-menu .navigation > li > ul > li{
    position:relative;
    width:100%;
    border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-menu .navigation > li > ul > li:last-child{
    border-bottom:none;
}

.main-menu .navigation > li > ul > li > a{
    position:relative;
    display:block;
    padding:11px 25px;
    line-height:24px;
    font-weight:500;
    font-size:15px;
    color:rgba(255,255,255,0.90);
    text-align:left;
    text-transform:capitalize;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover > a{
    color:#ffffff;
    background-color:#89c540;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
    font-family: 'Font Awesome 5 Free';
    content: "\f105";
    position:absolute;
    right:20px;
    top:11px;
    display:block;
    line-height:24px;
    font-size:16px;
    font-weight:900;
    z-index:5;
}

.main-menu .navigation > li > ul > li > ul{
    position:absolute;
    left:100%;
    top:0px;
    width:240px;
    z-index:100;
    display:none;
    background-color:#262626;
    opacity:0;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    -webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    -moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul > li > ul > li{
    position:relative;
    width:100%;
    border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
    border-bottom:none;
}

.main-menu .navigation > li > ul > li  > ul > li > a{
    position:relative;
    display:block;
    padding:11px 25px;
    line-height:24px;
    font-weight:500;
    font-size:15px;
    color:rgba(255,255,255,0.90);
    text-align:left;
    text-transform:capitalize;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li  > ul > li > a:hover{
    color:#ffffff;
    background-color:#89c540;
}

.main-menu .navigation > li.dropdown:hover > ul{
    visibility:visible;
    opacity:1;
    top:100%;
    -webkit-transform:translateY(0px);
    -ms-transform:translateY(0px);
    transform:translateY(0px);
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
    visibility:visible;
    opacity:1;
    top:0px;
    -webkit-transform:translateY(0px);
    -ms-transform:translateY(0px);
    transform:translateY(0px);
}

.main-menu .navigation li.dropdown .dropdown-btn{
    position:absolute;
    right:10px;
    top:8px;
    width:34px;
    height:30px;
    border:1px solid #ffffff;
    text-align:center;
    font-size:16px;
    line-height:26px;
    color:#ffffff;
    cursor:pointer;
    z-index:5;
    display:none;
}

.main-header .outer-box{
    position: relative;
    float: left;
}

/*search box btn*/

.main-header .search-box-outer{
    position:relative;
    float: left;
    padding: 42px 0px;
    -webkit-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    transition:all 300ms ease;
}

.main-header .search-box-btn{
    position: relative;
    height: 35px;
    cursor: pointer;
    background: none;
    font-size: 16px;
    color: #333333;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-header .search-box-btn:hover{
    color: #03274a;
}

.dropdown-toggle::after{
    display: none;
}

.main-header .search-box-outer .dropdown-menu{
    top:75px !important;
    left: auto !important;
    right: 0;
    padding:0px;
    width:280px;
    border-radius:0px;
    transform: none !important;
    border-top:3px solid #25262c;
}

.main-header .search-panel .form-container{
    padding:25px 20px;
}

.main-header .search-panel .form-group{
    position:relative;
    margin:0px;
}

.main-header .search-panel input[type="text"],
.main-header .search-panel input[type="search"],
.main-header .search-panel input[type="password"],
.main-header .search-panel select{
    display:block;
    width:100%;
    line-height:24px;
    padding:7px 40px 7px 15px;
    height:40px;
    font-size:14px;
    border:1px solid #e0e0e0;
    background:#ffffff;
}

.main-header .search-panel input:focus,
.main-header .search-panel select:focus{
    border-color:#25262c;
}

.main-header .search-panel .search-btn{
    position:absolute;
    right:0px;
    top:0px;
    width:40px;
    height:40px;
    text-align:center;
    color:#555555;
    font-size:12px;
    background:none;
    cursor:pointer;
}

.main-header .outer-box .btn-box{
    position: relative;
    float: right;
    padding: 34px 0px;
    margin-left: 35px;
}

.main-header .outer-box .btn-box .theme-btn{
    position: relative;
    float: right;
}

/*================================================
			Header Style Two
==================================================*/

.header-style-two{
    position: relative;
}

.header-style-two .header-upper .logo-outer{
    padding-bottom: 22px;
}

.header-style-two .header-lower .nav-outer{
    position: relative;
    background: #001e57;
    bottom: -10px;
    width: 100%;
}

.header-style-two .main-menu .navigation > li{
    margin-right: 0px;
    margin-left: 40px;
    padding: 0;
}

.header-style-two .main-menu .navigation > li > a{
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 30px;
    padding: 20px 0px;
}

.header-style-two .main-menu .navigation > li:hover > a,
.header-style-two .main-menu .navigation > li.current > a{
    color: #89c540;
}

.header-style-two .outer-box{
    float: right;
    padding: 0;
}

.header-style-two .outer-box .btn-box{
    padding: 0;
}

.header-style-two .outer-box .search-box-outer{
    padding: 18px 0px 16px;
}

.header-style-two .search-box-btn{
    color: #ffffff;
}

.header-style-two .search-box-btn:hover{
    color: #89c540;
}

.header-style-two .search-box-outer .dropdown-menu{
    border-top-color: #89c540;
    top: 50px !important;
}

.header-style-two .outer-box .btn-box .theme-btn{
    padding: 20px 30px;
    border-radius: 0px;
}

.header-style-two .outer-box .btn-box .theme-btn:hover{
    background: #89c540;
    opacity: 0.70;
    color: #ffffff;
}

.header-style-two .logo-outer{
    position: relative;
    float: left;
}

.header-style-two .info-outer{
    position: relative;
    float: right;
    padding-top: 30px;
}

.header-style-two .info-outer .info-box{
    position: relative;
    float: left;
    margin-left: 70px;
    padding-left: 45px;
    margin-bottom: 30px;
}

.header-style-two .info-outer .info-box .icon{
    position: absolute;
    left: 0;
    top: 0;
    line-height: 56px;
    font-size: 30px;
    color: #89c540;
}

.header-style-two .info-outer .info-box:first-child{
    margin-left: 0;
}

.header-style-two .info-outer .info-box:before{
    content: '';
    position: absolute;
    left: -36px;
    top: 0;
    height: 56px;
    border-left: 1px solid #e0e0e0;
}

.header-style-two .info-outer .info-box:first-child:before{
    display: none;
}

.header-style-two .info-outer .info-box .info{
    position: relative;
    display: block;
    line-height: 1.4em;
    font-size: 16px;
    color: #222222;
}

.header-style-two .info-outer .info-box strong{
    font-weight: 700;
    line-height: 1.2em;
    font-size: 15px;
    color: #222222;
}

.header-style-two .info-outer .info-box a{
    color: #222222;
}

.header-style-two .info-outer .info-box a:hover{
    text-decoration: underline;
    color: #89c540;
}

/*================================================
			Header Style Three
==================================================*/

.header-style-three{
    position: absolute;
    background: none;
}

.header-style-three .header-upper{
    background: none;
}

.header-style-three .main-menu .navigation > li > a{
    color: #ffffff;
}

.header-style-three .main-menu .navigation > li:hover > a,
.header-style-three .main-menu .navigation > li.current > a{
    color: #89c540;
}

.header-style-three .search-box-btn{
    color: #ffffff;
}

.header-style-three .search-box-btn:hover{
    color: #89c540;
}

.main-header .search-box-outer .dropdown-menu{
    border-top-color: #89c540;
}

/***

====================================================================
				Sticky Header
====================================================================

***/

.sticky-header{
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    z-index: -1;
    background: #ffffff;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -moz-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -ms-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -o-box-shadow: 0 0 15px rgba(0,0,0,0.10);
    box-shadow: 0 0 15px rgba(0,0,0,0.10);
    -webkit-transition: top 300ms ease;
    -moz-transition: top 300ms ease;
    -ms-transition: top 300ms ease;
    -o-transition: top 300ms ease;
    transition: top 300ms ease;
}

.fixed-header .sticky-header{
    opacity:1;
    z-index: 99999;
    visibility:visible;
}

.fixed-header .sticky-header .logo{
    padding: 10px 0;
}

.sticky-header .main-menu .navigation > li{
    margin-left: 32px !important;
    padding: 0;
    margin-right: 0;
    background-color: transparent;
}

.sticky-header .main-menu .navigation > li > a:before,
.sticky-header .main-menu .navigation > li:after{
    display: none !important;
}

.sticky-header .main-menu .navigation > li > a{
    padding:20px 0px !important;
    line-height:30px;
    font-size:16px;
    font-weight:600;
    color:#222222;
    text-transform:capitalize;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li.current-menu-item > a{
    opacity:1;
    color: #89c540;
}