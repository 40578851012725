.main-form{
    position: relative;
}

/* Contact Form */

.contact-form{
    position:relative;
}

.contact-form .form-group{
    position:relative;
    margin-bottom:22px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select{
    position:relative;
    display:block;
    width:100%;
    color:#a5a5a5;
    line-height:26px;
    padding:10px 28px;
    height:60px;
    font-size:14px;
    border-radius:2px;
    background:#f3f5f9;
    border:1px solid transparent;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    transition:all 300ms ease;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus{
    border-color:#89c540;
}

.contact-form .form-group textarea{
    position:relative;
    display:block;
    width:100%;
    line-height:26px;
    padding:10px 28px;
    color:#a5a5a5;
    height:200px;
    font-size:14px;
    resize:none;
    border-radius:2px;
    background:#f3f5f9;
    border:1px solid transparent;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    transition:all 300ms ease;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error{
    border-color:#ff0000 !important;
}

.contact-form label.error{
    display:block;
    line-height:24px;
    padding:5px 0px 0px;
    margin:0px;
    text-transform:uppercase;
    font-size:12px;
    color:#ff0000;
    font-weight:500;
}