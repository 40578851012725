/***

====================================================================
			Page Title
====================================================================

***/

.page-banner{
    position:relative;
    padding: 115px 0;
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
}

.page-banner:before{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content:'';
    background-image: -ms-linear-gradient(left, rgba(0,18,52,0.95) 0%, rgba(255,255,255,0) 100%);
    background-image: -moz-linear-gradient(left, rgba(0,18,52,0.95) 0%, rgba(255,255,255,0) 100%);
    background-image: -o-linear-gradient(left, rgba(0,18,52,0.95) 0%, rgba(255,255,255,0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(0,18,52,0.95)), color-stop(100, rgba(255,255,255,0)));
    background-image: -webkit-linear-gradient(left, rgba(0,18,52,0.95) 0%, rgba(255,255,255,0) 100%);
    background-image: linear-gradient(to right, rgba(0,18,52,0.95) 0%, rgba(255,255,255,0) 100%);
    z-index:0;
}

.page-banner .inner-container{
    position: relative;
    z-index:5;
}

.page-banner h1{
    position:relative;
    font-size:52px;
    color:#ffffff;
    line-height: 1.3em;
    font-weight: 700;
}

.page-banner .bread-crumb {
    position: relative;
    padding: 10px 0px;
}

.page-banner .bread-crumb li{
    position: relative;
    float: left;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    text-transform: capitalize;
    cursor: default;
    padding-right:  15px;
    margin-right: 12px;
}

.page-banner .bread-crumb li:before{
    position: absolute;
    right: -3px;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
    content: "\f105";
    font-weight:800;
    font-family: "Font Awesome 5 Free";
}

.page-banner .bread-crumb li:last-child{
    padding-right: 0;
    margin-right: 0;
}

.page-banner .bread-crumb li:last-child:before{
    display: none;
}

.page-banner .bread-crumb li a{
    color:#89c540;
    font-weight: 400;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.page-banner .bread-crumb li a:hover{
    color:#ffffff;
}


.page-banner.style-two{
    position:relative;
    padding: 110px 0 80px;
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
    background-image:url('/images/titleheader.jpg');
}