
.services-section{
    position:relative;
    padding-bottom:10px;
}

/* Services Block */

.services-block{
    position:relative;
    margin-bottom:40px;
}

.services-block .inner-box{
    position:relative;
    display:block;
    text-align:center;
    padding:55px 15px 60px;
    background-color:#f3f5f9;
    border-bottom:3px solid #001e57;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block .inner-box .big-icon{
    position:absolute;
    left:0px;
    top:80px;
    right:0px;
    bottom:0px;
    line-height:1em;
    font-size:200px;
    text-align:center;
    color:rgba(255,255,255,0.05);
}

.services-block .inner-box:hover{
    border-color:#89c540;
}

.services-block .inner-box:before{
    position:absolute;
    content:'';
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    opacity:0;
    visibility:visible;
    background-color:#001e57;
    transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    -webkit-transform:scaleY(0);
    -ms-transform:scaleY(0);
    -moz-transform:scaleY(0);
    transform:scaleY(0);
    -webkit-transform-origin:top;
    -ms-transform-origin:top;
    -moz-transform-origin:top;
    transform-origin:top;
}

.services-block .inner-box:hover::before{
    opacity:1;
    visibility:visible;
    -webkit-transform:scaleY(1);
    -ms-transform:scaleY(1);
    -moz-transform:scaleY(1);
    transform:scaleY(1);
}

.services-block .inner-box .icon-box{
    position:relative;
    font-size:60px;
    line-height:1em;
    color:#001e57;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block .inner-box:hover .text,
.services-block .inner-box:hover h3 a,
.services-block .inner-box:hover .icon-box{
    color:#ffffff;
}

.services-block .inner-box h3{
    position:relative;
    font-size:20px;
    font-weight:700;
    color:#222222;
    line-height:1.3em;
    margin-top:30px;
    margin-bottom:20px;
}

.services-block .inner-box h3 a{
    position:relative;
    color:#222222;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block .inner-box .text{
    position:relative;
    font-size:16px;
    line-height:1.7em;
    color:#555555;
    padding:0px 22px;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.services-block .inner-box .arrow{
    position:absolute;
    left:50%;
    bottom:0px;
    margin-bottom:-22px;
    width:44px;
    height:44px;
    color:#89c540;
    font-size:10px;
    line-height:44px;
    text-align:center;
    border-radius:50%;
    font-weight:800;
    margin-left:-20px;
    background-color:#ffffff;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    -webkit-box-shadow:0px 10px 15px rgba(0,0,0,0.05);
    -ms-box-shadow:0px 10px 15px rgba(0,0,0,0.05);
    box-shadow:0px 10px 15px rgba(0,0,0,0.05);
}

.services-block .inner-box:hover .arrow{
    background-color:#89c540;
    color:#ffffff;
}
