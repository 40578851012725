
/***

====================================================================
	Main Footer
====================================================================

***/

.main-footer{
    position:relative;
    background-color:#030e22;
}

.main-footer .footer-widget{
    position:relative;
    margin-bottom:40px;
}

.main-footer .widgets-section{
    position:relative;
    padding:90px 0px 40px;
    padding-bottom:40px;
}

.main-footer .footer-widget h2{
    position:relative;
    font-weight:700;
    color:#ffffff;
    font-size:20px;
    line-height:1.2em;
    margin-bottom:24px;
    padding-bottom:14px;
    text-transform:capitalize;
}

.main-footer .footer-widget h2:before{
    position:absolute;
    content:'';
    left:0px;
    bottom:0px;
    height:1px;
    width:50px;
    background-color:#89c540;
}

.main-footer .about-widget .text{
    position:relative;
    color:#cccccc;
    font-size:16px;
    line-height:1.6em;
    margin-top:22px;
    margin-bottom:32px;
}

/* Footer Service List */

.footer-service-list{
    position:relative;
}

.footer-service-list li{
    position:relative;
    margin-bottom:10px;
}

.footer-service-list li a{
    position:relative;
    color:#cccccc;
    font-size:16px;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    transition:all 300ms ease;
}

.footer-service-list li a:hover{
    color:#ffffff;
    text-decoration:underline;
}


/*News Widget Block*/

.news-widget-block{
    position:relative;
    margin-bottom:30px;
}

.news-widget-block:last-child{
    margin-bottom:0px;
}

.news-widget-block:last-child{
    margin-bottom:0px;
}

.news-widget-block .widget-inner{
    position:relative;
    padding-left:100px;
    min-height:75px;
}

.news-widget-block .widget-inner .image{
    position:absolute;
    left:0px;
    top:0px;
    width:85px;
    height:0px;
}

.news-widget-block .widget-inner .post-date{
    position:relative;
    color:#ffffff;
    font-size:12px;
    font-weight:400;
    margin-bottom:5px;
}

.news-widget-block .widget-inner h3{
    position:relative;
    color:#cccccc;
    font-size:18px;
    line-height:1.4em;
    font-weight:400;
    top:-4px;
    margin-bottom: 5px;
}

.news-widget-block .widget-inner h3 a{
    position:relative;
    color:#cccccc;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.news-widget-block .widget-inner h3 a:hover{
    color:#89c540;
}

.main-footer .contact-widget .number{
    position:relative;
    color:#ffffff;
    font-size:20px;
    font-weight:700;
    margin-bottom:15px;
}

.main-footer .contact-widget ul li{
    position:relative;
    color:#cccccc;
    font-size:16px;
    margin-bottom:12px;
}

.main-footer .contact-widget ul li a:hover{
    color:#ffffff;
    text-decoration:underline;
}

.main-footer .footer-bottom{
    position:relative;
    padding:30px 0px;
    border-top:1px solid rgba(255,255,255,0.10);
}

.main-footer .footer-bottom .copyright{
    position:relative;
    color:#cccccc;
    font-size:16px;
}

.main-footer .footer-bottom .social-links{
    position:relative;
}

.main-footer .footer-bottom .social-links li{
    position:relative;
    margin-left:15px;
    display:inline-block;
}

.main-footer .footer-bottom .social-links li a{
    position:relative;
    color:#cccccc;
    transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -webkit-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
}

.main-footer .footer-bottom .social-links li a:hover{
    color:#89c540;
}
